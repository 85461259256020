<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('MENU.management_subdomain')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>
                                <table class="table">
                                    <tbody>
                                        <tr v-if="data.assign_account_no">
                                            <th>{{$t('management_subdomain.assign_account_no')}}</th>
                                            <td>{{data.assign_account_no}}</td>
                                        </tr>
                                        <tr v-if="data.business_name_subdomain">
                                            <th>{{$t('management_subdomain.business_name_subdomain')}}</th>
                                            <td>{{data.business_name_subdomain}}</td>
                                        </tr>

                                        <tr v-if="data.db_name">
                                          <th>{{$t('management_subdomain.db_name')}}</th>
                                          <td>{{data.db_name}}</td>
                                        </tr>
                                        <tr v-if="data.business_name">
                                          <th>{{$t('management_subdomain.business_name')}}</th>
                                          <td>{{data.business_name}}</td>
                                        </tr>
                                        <tr v-if="data.expire_date">
                                          <th>{{$t('management_subdomain.expire_date')}}</th>
                                          <td>{{data.expire_date}}</td>
                                        </tr>
                                        <tr v-if="data.plan_name">
                                          <th>{{$t('management_subdomain.plan_name')}}</th>
                                          <td>{{data.plan_name}}</td>
                                        </tr>



                                        <tr>
                                            <th>{{$t('management_subdomain.available_flag')}}</th>
                                            <td>
                                                <v-icon v-if="data.available_flag" class="text-danger">mdi-circle</v-icon>
                                                <v-icon v-else class="text-success">mdi-circle</v-icon>
                                            </td>
                                        </tr>

                                        <tr v-if="data.notes">
                                            <th>{{$t('management_subdomain.notes')}}</th>
                                            <td>{{data.notes}}</td>
                                        </tr>
                                        <tr v-if="data.created_at">
                                            <th>{{$t('created_at')}}</th>
                                            <td>{{data.created_at}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                      <b-tab :title="$t('management_subdomain.subscription_information')" v-if="data.subscription">
                        <br>
                        <div>
                          <table class="table">
                            <tbody>
                            <tr v-if="data.subscription.first_name">
                              <th>{{$t('management_subdomain.first_name')}}</th>
                              <td>{{data.subscription.first_name}}</td>
                            </tr>
                            <tr v-if="data.subscription.last_name">
                              <th>{{$t('management_subdomain.last_name')}}</th>
                              <td>{{data.subscription.last_name}}</td>
                            </tr>

                            <tr v-if="data.subscription.email">
                              <th>{{$t('management_subdomain.email')}}</th>
                              <td>{{data.subscription.email}}</td>
                            </tr>

                            <tr v-if="data.subscription.phone">
                              <th>{{$t('management_subdomain.phone')}}</th>
                              <td><span style="direction: ltr;display: block;">{{data.subscription.phone}}</span></td>
                            </tr>
                            <tr v-if="data.subscription.country_name">
                              <th>{{$t('management_subdomain.country')}}</th>
                              <td>{{data.subscription.country_name}}</td>
                            </tr>
                            <tr v-if="data.subscription.city_name">
                              <th>{{$t('management_subdomain.city')}}</th>
                              <td>{{data.subscription.city_name}}</td>
                            </tr>
                            <tr v-if="data.subscription.address1">
                              <th>{{$t('management_subdomain.address1')}}</th>
                              <td>{{data.subscription.address1}}</td>
                            </tr>
                            <tr v-if="data.subscription.address2">
                              <th>{{$t('management_subdomain.address2')}}</th>
                              <td>{{data.subscription.address2}}</td>
                            </tr>
                            <tr v-if="data.subscription.start_date">
                              <th>{{$t('management_subdomain.start_date')}}</th>
                              <td>{{data.subscription.start_date}}</td>
                            </tr>
                            <tr v-if="data.subscription.end_date">
                              <th>{{$t('management_subdomain.end_date')}}</th>
                              <td>{{data.subscription.end_date}}</td>
                            </tr>





                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log :inner-key="key" :id="id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-management-subdomain",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: '/subscription/management_subdomains',
                id: this.$route.params.id? this.$route.params.id : null,
                data: {},
                key: "managementSubdomain",
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.management_subdomain"), route: {name:'management-subdomain.index'}}, {title: this.$t('view')}]);
            this.getData();

        },
    };
</script>