<template>
    <div class="timeline timeline-6 mt-3">


        <!--begin::Item-->
        <div class="timeline-item align-items-start" v-for="(row, index) in data" :key="index">
            <!--begin::Label-->
            <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg">{{row.created_at}}</div>
            <!--end::Label-->
            <!--begin::Badge-->
            <div class="timeline-badge">
                <i class="fa fa-genderless text-success icon-xl"></i>
            </div>
            <!--end::Badge-->
            <!--begin::Content-->
            <div class="timeline-content d-flex flex-column">
                <span class="font-weight-bolder text-dark-75 pl-3 pr-3 font-size-lg mb-3">{{$t('Added_By')}}: {{ row.user_name }} </span>
                <span class="font-weight-bolder text-dark-75 pl-3 pr-3 font-size-lg">{{row.description}} </span>
            </div>
            <!--end::Content-->
        </div>
        <!--end::Item-->
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    export default {
        name: "ActivityLog",
        props:['innerKey','id'],
        data(){
            return {
                mainRoute: 'base/activitylogs',
                data:{},
            }
        },
        methods:{
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.innerKey}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },
        mounted() {
            if (this.id) {
                this.getData();
            }

        },
    }
</script>

<style scoped>

</style>